import React from 'react';
import {Button, Form, Input, Upload} from "antd";
import {PaperClipOutlined, SendOutlined} from "@ant-design/icons";
import { Timestamp  } from "firebase/firestore";
import { ref, set } from "firebase/database";
import * as fbStorage from "firebase/storage";
import {useSelector} from "react-redux";
import hash from "object-hash"
import {db, storage} from "../firebase";
import dayjs from "dayjs";

const InputBox = () => {
	const [form] = Form.useForm()
	const {id, name, image} = useSelector(({user}) => user.data)

	function writeMessage(messageData) {
		const mid = hash({id: messageData.id, date: new Date()})
		set(ref(db, 'messages/' + mid), {
			...messageData,
			createdAt: Timestamp.now(),
			time: dayjs(new Date()).valueOf()
		});
	}

	const onFinish = ({message = null, attachments = []}) => {
		attachments = attachments.map(el => el.xhr)
		writeMessage({id, name, image, message, attachments})
		form.resetFields()
	}

	const uploadImage = ({file, onSuccess, onError, onProgress }) => {
		const storageRef = fbStorage.ref(storage, hash({name: file.name, id}))
		const uploadTask = fbStorage.uploadBytesResumable(storageRef, file)
		uploadTask.on('state_changed',
			(snapshot) => {
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				onProgress({percent: progress}, file)
			},
			(error) => {
				onError(error.code)
			},
			() => {
				fbStorage.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					onSuccess(uploadTask.snapshot, downloadURL)
				});
			}
		);

	}
	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};
	return (
		<Form
			form={form}
			onFinish={onFinish}
			className="message-input"
		>
			<Form.Item name="attachments" valuePropName="fileList" getValueFromEvent={normFile}>
				<Upload
					listType="picture-card"
					className="upload-list-inline"
					name="attachments"
					customRequest={uploadImage}
					multiple
				>
					<Button icon={<PaperClipOutlined/>}/>
				</Upload>
			</Form.Item>
			<Form.Item name="message" style={{width: 'calc(100% - 78px)'}}>
				<Input.TextArea
					autoSize={{minRows: 1, maxRows: 6}}
					name="message"
					placeholder="Write a message..."
					onPressEnter={() => form.submit()}
				/>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit"><SendOutlined /></Button>
			</Form.Item>
		</Form>
	);
};

export default InputBox;
