import React from 'react'
import {createUserWithEmailAndPassword} from "firebase/auth"
import Form from "./Form";

const Login = () => {
	return (
		<Form
			title="Signup page"
			method={createUserWithEmailAndPassword}
		/>
	);
};

export default Login;
