import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import store, {persistor} from "./store/store"
import './firebase'

import 'antd/dist/antd.css';
import './index.css';
import {PersistGate} from "redux-persist/integration/react";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <App />
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
