import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
	data: [],
	status: null,
	error: null
}

const setError = (state, action) => {
	state.status = 'rejected'
	state.error = action.payload
}

export const sendMessage = createAsyncThunk(
	'user/sendMessage',
	async ({method, auth}, {rejectedWithValue, dispatch}) => {
		try {
			await method(auth)
		} catch (e) {
			return rejectedWithValue(e.message)
		}
	}
)

const messagesSlice = createSlice({
	name: 'messages',
	initialState,
	reducers: {
		setMessages(state, action) {
			state.data = Object.values(action.payload).sort((a ,b) => a.time - b.time)
		}
	},
	extraReducers: {
		[sendMessage.rejected]: setError
	}
})

export const {setMessages} = messagesSlice.actions

export default messagesSlice.reducer