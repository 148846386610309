import React, {useEffect} from 'react';
import {Avatar, Image, Menu, Spin} from "antd";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {useDispatch, useSelector} from "react-redux";
import {setUsers} from "../store/slices/userSlice"

const UsersList = () => {
	const dispatch = useDispatch()
	const uRef = ref(db, 'users')
	const {users} = useSelector(({user}) => user)
	useEffect(() => {
		onValue(uRef, (snapshot) => {
			const data = snapshot.val();
			dispatch(setUsers(data))
		});
	}, [dispatch])

	return (
		<Menu
			mode="inline"
			defaultSelectedKeys={['1']}
			style={{ height: '100%' }}
		>
			{users.length > 0
			? users.map(user => (
				<Menu.Item key={user.id}>
					<Avatar src={<Image src={user.image} />}/> {user.name}</Menu.Item>
				)) : (
					<Spin/>
				)
			}
		</Menu>
	);
};

export default UsersList;
