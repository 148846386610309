import React, {useEffect, useRef} from 'react';
import Message from "./Message";
import {Empty} from "antd";
import { ref, onValue} from "firebase/database";
import {useDispatch, useSelector} from "react-redux";
import {setMessages} from "../store/slices/messageSlice";
import {db} from "../firebase";

const MessagesBox = () => {
	const dispatch = useDispatch()
	const {data} = useSelector(({messages}) => messages)
	const messages = useRef(null)
	const messageRef = ref(db, 'messages')
	useEffect(() => {
		onValue(messageRef, (snapshot) => {
			const data = snapshot.val();
			dispatch(setMessages(data))
		});
	}, [dispatch])

	useEffect(() => {
		messages.current.scrollTop = messages.current.scrollHeight - messages.current.clientHeight
	}, [data])

	return (
		<div ref={messages} className="messages">
			{data.length > 0
				? (
					data.map(el => <Message key={el.mid} data={el}/>)
				) : (
					<Empty />
				)}
		</div>
	);
};

export default MessagesBox;
