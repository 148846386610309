import React from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import {privateRoutes, publicRoutes} from "../routes";
import {CHAT_ROUTE, LOGIN_ROUTE} from "../utils/consts";
import {useAuth} from "../hooks/useAuth";

const AppRouter = () => {
	const {isAuth} = useAuth()
	return isAuth ?
		(
			<Routes>
				{privateRoutes.map(({path, Component}) => <Route key={path} path={path} element={<Component />} exact={true} /> )}
				<Route path="*" element={<Navigate to={CHAT_ROUTE} />} />
			</Routes>
		) : (
			<Routes>
				{publicRoutes.map(({path, Component}) => <Route key={path} path={path} element={<Component />} exact={true} /> )}
				<Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
			</Routes>
		)
};

export default AppRouter;
