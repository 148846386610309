import React from 'react';
import {Grid, Layout, Menu} from "antd";
import MessagesBox from "./MessagesBox";
import InputBox from "./InputBox";
import UsersList from "./UsersList";
const {Content, Sider} = Layout;


const Chat = () => {
	return (
		<>
			<Sider width={200} className="layout-sidebar">
				<UsersList />
			</Sider>
			<Content className="layout-message-box">
				<MessagesBox />
				<InputBox />
			</Content>
		</>
	);
};

export default Chat;
