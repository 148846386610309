import React from 'react';
import {Menu, Layout} from "antd";
import {Link} from "react-router-dom";
import {publicRoutes} from "../routes";
import {useAuth} from "../hooks/useAuth";
import {useDispatch} from "react-redux";
import {signOutUser} from "../store/slices/userSlice";
import {getAuth, signOut } from "firebase/auth";

const Header = () => {
	const {isAuth} = useAuth()
	const dispatch = useDispatch()
	const auth = getAuth()
	const logoutHandle = () => {
		dispatch(signOutUser({
			method: signOut,
			auth
		}))
	}
	return (
		<Layout.Header className="header">
			<div className="logo" />
			<Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
				{isAuth ? (
					<Menu.Item onClick={logoutHandle} key="logout">Logout</Menu.Item>
				) :
					publicRoutes.map(el => (
							<Menu.Item key={el.name}>
								<Link to={el.path}>{el.name}</Link>
							</Menu.Item>
						))
				}
			</Menu>
		</Layout.Header>
	);
};

export default Header;
