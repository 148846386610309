import React from 'react'
import {signInWithEmailAndPassword } from "firebase/auth"
import Form from "./Form";

const Login = () => {
	return (
		<Form
			title="Login page"
			method={signInWithEmailAndPassword}
		/>
	);
};

export default Login;
