import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./components/Header";
import AppRouter from "./components/AppRouter";
import {Layout} from "antd";
const {Content, Footer} = Layout;

const App = () => {
  return (
    <Router>
        <Layout>
            <Header />
            <Content style={{ padding: '0 50px', height:'calc(100vh - 134px)' }}>
                <Layout className="site-layout-background">
                    <AppRouter />
                </Layout>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Copyright ©2022</Footer>
        </Layout>
    </Router>
  );
}

export default App;
