import {CHAT_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE} from "./utils/consts";
import Login from "./components/Login";
import Chat from "./components/Chat";
import Signup from "./components/Signup";

export const publicRoutes = [
	{
		path: LOGIN_ROUTE,
		Component: Login,
		name: 'Login'
	},
	{
		path: SIGNUP_ROUTE,
		Component: Signup,
		name: 'Sign up'
	}
]

export const privateRoutes = [
	{
		path: CHAT_ROUTE,
		Component: Chat
	}
]