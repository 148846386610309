import React from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Avatar, Image} from "antd";
import dayjs from "dayjs";
import {Timestamp} from "firebase/firestore";


const Message = ({data}) => {
	const {id} = useSelector(({user}) => user.data)
	return (
		<div className={classNames({
			'message': true,
			'ownMessage': id === data.uid
		})}>
			{data.attachments && (
				<Image.PreviewGroup>
					{data.attachments.map(el => <Image key={el} className="message-image" src={el} />)}
				</Image.PreviewGroup>
			)}
			<div className="message-wrap">
				<Avatar src={<Image src={data.image} />}/>
				{ data.message && ( <span className="message-text">{data.message}</span> ) }
			</div>
			<div className="message-date">{dayjs(data.time).format('h:mm A')}</div>
		</div>
	);
};

export default Message;
