import React, {useEffect} from 'react';
import {Button, Col, Input, Row, Typography, Form as AntdForm, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {fetchUser} from "../store/slices/userSlice";
import {ref, set} from "firebase/database";
import {db} from "../firebase";

const Form = ({title, method}) => {
	const [form] = AntdForm.useForm()
	const {status, error} = useSelector(({user}) => user)
	const dispatch = useDispatch()
	const auth = getAuth()
	const provider = new GoogleAuthProvider();

	useEffect(() => {
		status === 'resolved' && message.success('Success!')
		status === 'rejected' && message.error(error)
	}, [status])

	const googleLogin = async () => {
		dispatch(fetchUser({
			method: signInWithPopup,
			auth,
			values: provider
		})).then(({payload}) => {
			set(ref(db, 'users/' + payload.id), payload);
		})

	}
	const onFinish = (values) => {
		dispatch(fetchUser({
			method, auth, values
		}))
	}

	return (
		<Row justify="center">
			<Col span={8}>
				<Typography.Title style={{textAlign: 'center', textTransform: 'uppercase'}} level={3}>{title}</Typography.Title>
				<AntdForm
					form={form}
					name={title}
					onFinish={onFinish}
					layout="vertical"
				>
					<AntdForm.Item
						label="Email"
						name="email"
						rules={[{
								required: true,
								message: 'Please input your email!',
							}]}
					>
						<Input name="email" />
					</AntdForm.Item>

					<AntdForm.Item
						label="Password"
						name="password"
						rules={[
							{
								required: true,
								message: 'Please input your password!',
							},
						]}
					>
						<Input.Password name="password" />
					</AntdForm.Item>
					<AntdForm.Item>
						<Button type="primary" htmlType="submit" loading={status === 'loading'}>
							Submit
						</Button>
					</AntdForm.Item>
				</AntdForm>
				<Button onClick={googleLogin} style={{width: '100%'}} type="primary">Login with google</Button>
			</Col>
		</Row>
	);
};

export default Form;
